import React, { useContext } from 'react';
import { useTimer } from './TimerContext';
import AppContext from './AppContext';
import { useNavigate } from 'react-router-dom';

const ExitAlert = ({ message, show, onClose }) => {
	const { setIsDisabled, keyExam } = useContext(AppContext);

	const { restartTimer, isPaused, setIsPaused } = useTimer();
	const finish = useNavigate();

	if (!show) return null;
	const urlAdress = window.location.href;
	const id = urlAdress.split('/').pop();
	const exit = () => {
		setIsPaused(!isPaused);
		setIsDisabled(true); // blokowanie formularza
		restartTimer();
		finish(`/finish/${id}`);
	};

	return (
		<div className='custom-alert-overlay'>
			<div className='custom-alert'>
				<div className='row'>
					<div className='col-11 alert-header'>Potwierdzenie</div>
					<div className='col-1 close' onClick={onClose}>
						x
					</div>
				</div>
				<div className='box-message col-12'>
					<div className='custom-alert-message'>{message}</div>
				</div>
				<div className='alertButtons'>
					<button className='btn btn-success' onClick={onClose}>
						Nie, pozostań
					</button>
					<button className='btn btn-danger custom-alert-button' onClick={exit}>
						Tak, zakończ
					</button>
				</div>
			</div>
		</div>
	);
};

export default ExitAlert;
