import React from 'react';
import ckeLogo from '../cke.png';

const Footer = () => {
	return (
		<div className='footer'>
			<div className='row foot-info'>
				<div className='col-lg-12 version'>
					<small>WERSJA 1.2.3</small>
				</div>
			</div>
			<div className='row foot-info2'>
				<div className='col-lg-12 col-12 foot'>
					<small>PROJEKT POWSTAŁ W CELACH EDUKACYJNYCH.</small>
				</div>
			</div>
		</div>
	);
};

export default Footer;
