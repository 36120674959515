import React, { useContext } from 'react';
import logoCodenight from '../logo-blue.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import AppContext from './AppContext';

library.add(faCircleQuestion);

const Header = () => {
	const {selectedAnswers} = useContext(AppContext);
	const countAnswersInSelectedAnswers = () => {
		return selectedAnswers.reduce((count, answer) => {
			return answer !== 'null' ? count + 1 : count;
		}, 0);
	};
	const countUserAnswers = countAnswersInSelectedAnswers();
	console.log(countUserAnswers);
	return (
		<div>
			<div className='row header'>
				<div className='col-lg-4 col-md-12'>
					<img
						className='logoCodenight'
						style={{ width: '85%', height: '85%' }}
						src={logoCodenight}
					/>
				</div>
				<div className='col-6 header-right '>
					<Link
						target='_blank'
						to='https://exam.codenight.pl/instruction-exam.pdf'
					>
						<FontAwesomeIcon
							className='help'
							icon='fa-solid fa-circle-question'
						/>

						<span className='light-green'>&nbsp;Instrukcja obsługi</span>
					</Link>
				</div>
				<div className='col-2 header-right'>
					Rozwiązań: {countUserAnswers}/40
				</div>
				<div className='wrapper'></div>
			</div>
		</div>
	);
};

export default Header;
